import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import useToast from './useToast';

import { getIntermediateAuthToken, getRedirectData } from '../utils/global.util';

function useRedirect() {
    var history = useHistory();
    var location = useLocation();
    var toast = useToast();

    function redirectWithToken(token, domain, redirectUrl) {
        return window.location.replace(`${domain}/?redirectUrl=${redirectUrl}&code=${token}`);
    }

    function tokenFailure(errMessage) {
        toast.showFailureToast(errMessage);
        history.replace('/profile');
    }

    function redirect(history) {
        var { sameDomain, redirectUrl, domain } = getRedirectData();
        if (sameDomain) {
            return history.replace(redirectUrl);
        }

        let oAuth = false;
        process.env.REACT_APP_OAUTH_DOMAIN.split(',').forEach(url => {
            if (queryString.parse(location.search)?.domain.includes(url)) {
                oAuth = true;
            }
        });

        if (
            queryString.parse(location.search)?.domain.includes('localhost:3001') ||
            queryString.parse(location.search)?.domain.includes('localhost:8000')
        ) {
            oAuth = true;
        }

        getIntermediateAuthToken(oAuth, tokenFailure).then(token => {
            redirectWithToken(token, domain, redirectUrl);
        });
    }

    function functionalRedirect(domain, redirectUrl, generateToken = true) {
        if (!generateToken) {
            return window.open(domain + redirectUrl, '_self');
        }

        let oAuth = false;
        process.env.REACT_APP_OAUTH_DOMAIN.split(',').forEach(url => {
            if (domain.includes(url)) {
                oAuth = true;
            }
        });
        getIntermediateAuthToken(oAuth, tokenFailure).then(token => {
            redirectWithToken(token, domain, redirectUrl);
        });
    }

    return {
        redirect,
        functionalRedirect,
    };
}

export default useRedirect;
