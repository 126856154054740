import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useUserContext from '../../hooks/useUserContext';
import useRedirect from '../../hooks/useRedirect';
import { logout } from '../../store/actions/user.action';
import './Nav.css';

function Nav() {
    var { user, dispatch } = useUserContext();
    var { functionalRedirect } = useRedirect();
    var [showProfileList, setShowProfileList] = useState(false);
    var [showSidebar, setShowSidebar] = useState(false);
    var [show, setShow] = useState(false);

    var profileRef = useRef();
    var profileListRef = useRef();
    var sidebar = useRef();

    function onWindowResize() {
        var profileLeftPos = profileRef.current.getBoundingClientRect().left;
        var profileWidth = profileRef.current.offsetWidth;

        profileWidth = profileWidth - 170 > 0 ? profileWidth : 170;

        profileListRef.current.style.left = profileLeftPos + 'px';
        profileListRef.current.style.width = profileWidth + 'px';
    }

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    useEffect(() => {
        onWindowResize();
    }, []);

    useEffect(() => {
        if (showSidebar) {
            sidebar.current = document.querySelector('.sidebar');
            return setShow(true);
        }

        if (sidebar?.current) {
            sidebar.current.style.animation = 'close-animation 0.2s';
            setTimeout(() => {
                setShow(false);
            }, 200);
        }
    }, [showSidebar]);

    function courseRedirect() {
        functionalRedirect(process.env.REACT_APP_COURSE_DOMAIN, '/courses');
    }

    function internshipRedirect() {
        functionalRedirect(process.env.REACT_APP_INTERNSHIP_DOMAIN, 'dashboard');
    }

    function learnRedirect() {
        functionalRedirect(process.env.REACT_APP_LEARN_DOMAIN, '/');
    }

    function oneNeuronRedirect() {
        functionalRedirect(process.env.REACT_APP_COURSE_DOMAIN, '/one-neuron');
    }

    function neuroLabRedirect() {
        functionalRedirect(process.env.REACT_APP_NEUROLAB_DOMAIN, '/my-labs');
    }

    function jobPortalRedirect() {
        functionalRedirect(process.env.REACT_APP_JOBS_DOMAIN, '/');
    }

    function affiliateRedirect() {
        functionalRedirect(process.env.REACT_APP_AFFILIATE_DOMAIN, '/dashboard');
    }

    function logoutUser() {
        setTimeout(() => {
            dispatch(logout());
        }, 500);
    }

    let imageLink;
    let imageUrl = user?.img;
    if (imageUrl?.source === 'bucket') {
        imageLink = process.env.REACT_APP_IMAGE_DOMAIN + '/' + imageUrl.link;
    } else if (imageUrl?.source === 'oauth') {
        imageLink = imageUrl.link;
    }

    return (
        <React.Fragment>
            <nav className="navbar">
                <div className="container flex">
                    <div className="navbar-left">
                        <div className="logo">
                            <a href={process.env.REACT_APP_BRAND_DOMAIN}>
                                <img src="/static/images/ineuron-logo.png" alt="ineuron-logo" />
                            </a>
                        </div>
                        <ul>
                            <button onClick={courseRedirect}>
                                <li>Courses</li>
                            </button>
                            <button onClick={oneNeuronRedirect}>
                                <li>One Neuron</li>
                            </button>
                            <button onClick={neuroLabRedirect}>
                                <li>Neuro Lab</li>
                            </button>
                            <button onClick={jobPortalRedirect}>
                                <li>Job Portal</li>
                            </button>
                            <button onClick={internshipRedirect}>
                                <li>Internship</li>
                            </button>
                            <button onClick={affiliateRedirect}>
                                <li>Become an affiliate</li>
                            </button>
                            <a href="https://halloffame.ineuron.ai">Hall of Fame</a>
                            {/* <a href="https://blog.ineuron.ai">Blog</a> */}
                        </ul>
                    </div>
                    <div className="navbar-right">
                        <div className="profile flex" ref={profileRef} onMouseEnter={() => setShowProfileList(true)}>
                            <span>
                                {user.firstName} <img src="/static/images/dropdown.svg" alt="dropdown" />
                            </span>
                            {imageLink ? (
                                <img className="profile-pic" src={imageLink} alt="profile-icon" />
                            ) : (
                                <img
                                    className="profile-pic"
                                    src="/static/images/default-user-icon.svg"
                                    alt="profile-icon"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={`profile-list ${showProfileList ? 'card' : ''}`}
                    ref={profileListRef}
                    onMouseLeave={() => setShowProfileList(false)}>
                    {showProfileList && (
                        <ul style={{ listStyleType: 'none' }}>
                            {/* <Link
                                onClick={e => {
                                    e.preventDefault();
                                    courseRedirect();
                                }}>
                                <li>
                                    <i className="fas fa-book-open"></i> Courses Library
                                </li>
                            </Link> */}
                            <Link
                                href="#"
                                onClick={e => {
                                    e.preventDefault();
                                    learnRedirect();
                                }}>
                                <li>
                                    <i className="fas fa-book"></i> My Courses
                                </li>
                            </Link>
                            <Link href="/profile">
                                <li>
                                    <i className="fas fa-user"></i> My Profile
                                </li>
                            </Link>
                            <Link href="#" onClick={logoutUser}>
                                <li>
                                    <i className="fas fa-sign-out-alt"></i> Logout
                                </li>
                            </Link>
                        </ul>
                    )}
                </div>
            </nav>

            <div className="navbar-mobile">
                <div className="container flex">
                    <div id="hamburger" className="hamburger" onClick={() => setShowSidebar(true)}>
                        <img src="/static/images/hamburger.svg" alt="hamburger" />
                    </div>
                    <div className="logo">
                        <a href={process.env.REACT_APP_BRAND_DOMAIN}>
                            <img src="/static/images/ineuron-logo.png" alt="ineuron" />
                        </a>
                    </div>
                    <div id="profile-mobile" className="profile-mobile" onClick={() => setShowProfileList(val => !val)}>
                        {imageLink ? (
                            <img className="profile-pic" src={imageLink} alt="profile-icon" />
                        ) : (
                            <img
                                className="profile-pic"
                                src="/static/images/default-user-icon.svg"
                                alt="profile-icon"
                            />
                        )}
                        <div className={`profile-mobile-list ${showProfileList ? 'card' : ''}`}>
                            {showProfileList && (
                                <ul style={{ listStyleType: 'none' }}>
                                    <Link
                                        href="#"
                                        onClick={e => {
                                            e.preventDefault();
                                            learnRedirect();
                                        }}>
                                        <li>
                                            <i className="fas fa-book"></i> My Courses
                                        </li>
                                    </Link>
                                    <Link href="/profile">
                                        <li>
                                            <i className="fas fa-user"></i> My Profile
                                        </li>
                                    </Link>
                                    <Link href="#" onClick={logoutUser}>
                                        <li>
                                            <i className="fas fa-sign-out-alt"></i> Logout
                                        </li>
                                    </Link>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {show && (
                <div className="nav-sidebar">
                    <div className="navbar-sidebar">
                        <div className="sidebar" ref={sidebar}>
                            <div className="sidebar-header">
                                <div className="text-center">Menu</div>
                            </div>
                            <div className="sidebar-content">
                                <ul>
                                    <Link onClick={courseRedirect}>
                                        <li>Courses</li>
                                    </Link>
                                    <Link onClick={oneNeuronRedirect}>
                                        <li>One Neuron</li>
                                    </Link>
                                    <Link onClick={neuroLabRedirect}>
                                        <li>Neuro Lab</li>
                                    </Link>
                                    <Link onClick={jobPortalRedirect}>
                                        <li>Job Portal</li>
                                    </Link>
                                    <Link onClick={internshipRedirect}>
                                        <li>Internship</li>
                                    </Link>
                                    <Link onClick={affiliateRedirect}>
                                        <li>Become an affiliate</li>
                                    </Link>
                                    <a href="https://halloffame.ineuron.ai">
                                        <li>Hall of Fame</li>
                                    </a>
                                    {/* <a href="https://blog.ineuron.ai">
                                        <li>Blog</li>
                                    </a> */}
                                </ul>
                            </div>
                        </div>
                        <div id="close-sidebar" className="close-sidebar flex" onClick={() => setShowSidebar(false)}>
                            <div className="flex">
                                <img src="/static/images/close.svg" alt="close" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default Nav;
