import SignIn from './pages/Signin/Signin.page';
import ServerError from './pages/ServerError/ServerError.page';
import SignUp from './pages/Signup/Signup.page';
import Profile from './pages/Profile/Profile.page';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword.page';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';

import * as constants from './utils/constant.util';
import withAuth from './hoc/withAuth';

export const routes = [
    {
        path: constants.HOME_ROUTE,
        isRedirected: true,
        redirectPath: constants.PROFILE_ROUTE,
        exact: true,
    },
    {
        path: constants.PROFILE_ROUTE,
        Component: withAuth(Profile),
        isProtected: true,
    },
    {
        path: constants.SIGNIN_ROUTE,
        Component: SignIn,
        isProtected: false,
    },
    {
        path: constants.SIGNUP_ROUTE,
        Component: SignUp,
        isProtected: false,
    },
    {
        path: constants.FORGOTPASSWORD_ROUTE,
        Component: ForgotPassword,
        isProtected: false,
    },
    {
        path: constants.VERIFY_EMAIL,
        Component: VerifyEmail,
        isProtected: false,
    },
    {
        path: constants.SERVERERROR_ROUTE,
        Component: ServerError,
    },
];
