import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Toast.css';

function Toast() {
    return <ToastContainer />;
}

export default Toast;
