import React, { useEffect, useRef, useState } from 'react';
import MobileInput from '../../components/MobileInput/MobileInput';
import Nav from '../../components/Nav/Nav';
import useUserContext from '../../hooks/useUserContext';
import { Field, Form, Formik } from 'formik';

import './Profile.css';
import { deleteAccount, logout, updateProfile, updateProfileImage } from '../../store/actions/user.action';
import { phoneValidatorProfile, profileSchema } from '../../utils/validation.util';
import useTitle from '../../hooks/useTitle';
import EmailVerification from '../../components/EmailVerification/EmailVerification';
import useMobile from '../../hooks/useMobile';
import { sanitizeObject } from '../../utils/global.util';
import Loader from '../../components/Loader/Loader';
import Modal from '../../components/Modal/Modal';

function ProfilePage() {
    var { user, dispatch, isLoading } = useUserContext();
    var [showEmailVerification, setShowEmailVerification] = useState(false);
    var [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    var [key, setKey] = useState(1);
    var { state, error, ...rest } = useMobile({
        mobileValidator: phoneValidatorProfile,
        initialValue: {
            phoneNumber: (user.isdCode + user.phoneNumber)?.substring(1),
            isdCode: user.isdCode,
            mobile: user.phoneNumber,
        },
    });
    var fileElement = useRef();

    useTitle('Profile | iNeuron.ai');

    function getImageUrl() {
        let imageUrl = user?.img;
        if (imageUrl?.source === 'bucket') {
            return process.env.REACT_APP_IMAGE_DOMAIN + '/' + imageUrl.link;
        } else if (imageUrl?.source === 'oauth') {
            return imageUrl.link;
        }
    }

    async function handleProfilePicChange(e) {
        await dispatch(updateProfileImage(e.target.files[0], user._id));
    }

    useEffect(() => {
        if (user && !user.verified) {
            setShowEmailVerification(true);
        }
    }, [user]);

    async function handleSubmit(data) {
        if (error) return;
        let updatedProfileData = { ...data, phoneNumber: state.mobile, isdCode: state.isdCode };

        if (updatedProfileData.password !== updatedProfileData.confirmPassword) return;

        let [isChanged, val] = sanitizeObject(
            {
                ...updatedProfileData,
                phoneNumber: state.mobile,
                isdCode: state.isdCode,
            },
            {
                firstName: user.firstName,
                lastName: user.lastName,
                isdCode: user.isdCode,
                phoneNumber: user.phoneNumber,
                social: {
                    facebook: '',
                    instagram: '',
                    twitter: '',
                    youtube: '',
                    linkedin: '',
                    github: '',
                    ...user?.social,
                },
                password: '',
                confirmPassword: '',
            },
        );

        const newSocial = Object.keys(updatedProfileData.social).reduce((acc, val) => {
            var value = updatedProfileData.social[val];
            if (value) {
                acc[val] = value;
            }
            return acc;
        }, {});

        if (isChanged) {
            dispatch(
                updateProfile(
                    {
                        ...val,
                        firstName: updatedProfileData.firstName,
                        lastName: updatedProfileData.lastName,
                        isdCode: state.isdCode,
                        phoneNumber: state.mobile,
                        social: newSocial,
                    },
                    () => {
                        setKey(Math.floor(Math.random() * 1000));
                    },
                ),
            );
        }
    }
    var initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        isdCode: user.isdCode,
        phoneNumber: user.phoneNumber,
        email: user.email,
        password: '',
        confirmPassword: '',
        social: {
            linkedin: user?.social?.linkedin ? user.social?.linkedin : '',
            twitter: user?.social?.twitter ? user.social?.twitter : '',
            github: user?.social?.github ? user.social?.github : '',
            youtube: user?.social?.youtube ? user.social?.youtube : '',
            instagram: user?.social?.instagram ? user.social?.instagram : '',
            facebook: user?.social?.facebook ? user.social?.facebook : '',
        },
    };

    let imageLink = getImageUrl();
    if (user?.img?.source === 'bucket') {
        imageLink = `${imageLink}?${Date.now()}`;
    }

    const handleCloseModal = () => {
        setShowDeleteAccountModal(false);
    };

    async function handleDeleteAccount() {
        await dispatch(deleteAccount());
        await dispatch(logout());
    }

    return (
        <React.Fragment>
            {showEmailVerification && <EmailVerification close={() => setShowEmailVerification(false)} />}
            {isLoading && <Loader />}
            <Nav />
            <section className="user-profile">
                <div className="container">
                    <h4>My Profile</h4>
                    <Formik
                        key={key}
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={profileSchema}>
                        {({ errors, touched, dirty }) => {
                            return (
                                <Form>
                                    <div className="user-profile-details">
                                        <div className="display-picture">
                                            <div className="profile-image">
                                                <label htmlFor="profile-image">
                                                    {imageLink ? (
                                                        <img className="profile-pic" src={imageLink} alt="" />
                                                    ) : (
                                                        <img
                                                            src="/static/images/default-user-icon.svg"
                                                            alt=""
                                                            className="profile-pic"
                                                        />
                                                    )}
                                                </label>
                                                <div
                                                    className="profile-wrapper"
                                                    onClick={() => fileElement.current.click()}>
                                                    <i className="fa fa-edit profile-update-icon"></i>
                                                    <input
                                                        ref={fileElement}
                                                        id="profile-image"
                                                        style={{ display: 'none' }}
                                                        type="file"
                                                        accept=".png,.jpg,.jpeg"
                                                        onChange={handleProfilePicChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="general-details grid">
                                            <Field
                                                id="first-name"
                                                className="profile-input"
                                                type="text"
                                                name="firstName"
                                                placeholder="First Name"
                                            />
                                            <Field
                                                id="last-name"
                                                className="profile-input"
                                                type="text"
                                                name="lastName"
                                                placeholder="Last Name"
                                            />
                                            <div className="input-field">
                                                <Field
                                                    id="email"
                                                    className="profile-input"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    readOnly="true"
                                                    style={{ opacity: '0.5' }}
                                                />
                                                {touched.email && errors.email && (
                                                    <p className="error-field error-field-profile">{errors.email}</p>
                                                )}
                                            </div>
                                            <div className="input-field">
                                                <MobileInput
                                                    {...rest}
                                                    {...state}
                                                    containerClass="mobile-wrapper-profile"
                                                />
                                                {rest.touched && error && <p className="error-field">{error}</p>}
                                            </div>
                                            <Field
                                                id="password"
                                                className="profile-input"
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                            />
                                            <div className="input-field">
                                                <Field
                                                    id="confirm-password"
                                                    className="profile-input"
                                                    type="password"
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password"
                                                />
                                                {touched.confirmPassword && errors.confirmPassword && (
                                                    <p className="error-field error-field-profile">
                                                        {errors.confirmPassword}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="social-details">
                                            <h5>Social Accounts</h5>
                                            <div className="social-details-input grid">
                                                <div className="input-field">
                                                    <Field
                                                        id="facebook"
                                                        className="profile-input"
                                                        type="text"
                                                        name="social.facebook"
                                                        placeholder="Facebook"
                                                    />
                                                    {touched.social?.facebook && errors.social?.facebook && (
                                                        <p className="error-field error-field-profile">
                                                            {errors.social?.facebook}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="input-field">
                                                    <Field
                                                        id="twitter"
                                                        className="profile-input"
                                                        type="text"
                                                        name="social.twitter"
                                                        placeholder="Twitter"
                                                    />
                                                    {touched.social?.twitter && errors.social?.twitter && (
                                                        <p className="error-field error-field-profile">
                                                            {errors.social?.twitter}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="input-field">
                                                    <Field
                                                        id="instagram"
                                                        className="profile-input"
                                                        type="text"
                                                        name="social.instagram"
                                                        placeholder="Instagram"
                                                    />
                                                    {touched.social?.instagram && errors.social?.instagram && (
                                                        <p className="error-field error-field-profile">
                                                            {errors.social?.instagram}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="input-field">
                                                    <Field
                                                        id="linkedin"
                                                        className="profile-input"
                                                        type="text"
                                                        name="social.linkedin"
                                                        placeholder="Linkedin"
                                                    />
                                                    {touched.social?.linkedin && errors.social?.linkedin && (
                                                        <p className="error-field error-field-profile">
                                                            {errors.social?.linkedin}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="input-field">
                                                    <Field
                                                        id="youtube"
                                                        className="profile-input"
                                                        type="text"
                                                        name="social.youtube"
                                                        placeholder="Youtube"
                                                    />
                                                    {touched.social?.youtube && errors.social?.youtube && (
                                                        <p className="error-field error-field-profile">
                                                            {errors.social?.youtube}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="input-field">
                                                    <Field
                                                        id="github"
                                                        className="profile-input"
                                                        type="text"
                                                        name="social.github"
                                                        placeholder="GitHub"
                                                    />
                                                    {touched.social?.github && errors.social?.github && (
                                                        <p className="error-field error-field-profile">
                                                            {errors.social?.github}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                            disabled={!dirty && !rest.dirty}
                                            style={{
                                                opacity: !dirty && !rest.dirty ? '0.5' : '1',
                                            }}>
                                            Save Changes
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                    <div className="security-div">
                        <h5>Delete your account</h5>
                        <p>All your data (enrolled courses, course analytics, leaderboard, etc.) will get deleted.</p>
                        <button className="btn delete-account-btn" onClick={() => setShowDeleteAccountModal(true)}>
                            Delete Account
                        </button>
                    </div>
                </div>
            </section>
            {showDeleteAccountModal && (
                <Modal
                    header="Delete Account"
                    body="All your data (enrolled courses, course analytics, leaderboard, etc.) will get deleted."
                    footer={
                        <div className="modal-footer" style={{ display: 'flex', gap: '10px' }}>
                            <button className="btn" onClick={handleCloseModal}>
                                Cancel
                            </button>
                            <button className="btn btn-primary" onClick={handleDeleteAccount}>
                                Delete
                            </button>
                        </div>
                    }
                    close={handleCloseModal}
                />
            )}
        </React.Fragment>
    );
}

export default ProfilePage;
