import React from 'react'
import { Link } from 'react-router-dom'

import { SIGNIN_ROUTE } from '../../utils/constant.util'

function ServerErrorPage({ images }) {
    return (
        <section class="error">
            <img
                id="top-illustration"
                src={images.topIllustration}
                alt="error-illustration"
            />
            <img
                id="bottom-illustration"
                src={images.bottomIllustration}
                alt="error-illustration"
            />
            <div class="container">
                <div class="header">
                    <div class="logo">
                        <img src={images.logo} alt="ineuron-logo" />
                    </div>
                </div>
                <div class="error-div flex">
                    <div class="grid">
                        <div class="left">
                            <h1>503 Error</h1>
                            <p>The server is temporarily busy, try again later!</p>
                            <Link to={SIGNIN_ROUTE} class="btn btn-secondary">Back to Home</Link>
                        </div>
                        <div class="right">
                            <div class="icon">
                                <img src={images.serverErrorIcon} alt="503-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServerErrorPage
