import { useEffect } from 'react';
import { actionLogout, checkForAlreadyLogin } from '../store/actions/user.action';
import useUserContext from './useUserContext';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

function useAutoLogin() {
    var { dispatch } = useUserContext();

    var location = useLocation();
    var query = queryString.parse(location.search);

    useEffect(() => {
        if (query.action === 'logout') {
            // dispatch(actionLogout());
            if (query.domain) {
                if (query.domain.includes('localhost')) {
                    // return (window.location.href = `http://${query.domain}`);
                    return dispatch(actionLogout(`http://${decodeURIComponent(query.domain)}`));
                }
                // return (window.location.href = `https://${query.domain}`);
                return dispatch(actionLogout(`https://${query.domain}`));
            }
            // return (window.location.href = process.env.REACT_APP_BRAND_DOMAIN);
            return dispatch(actionLogout(process.env.REACT_APP_BRAND_DOMAIN));
        }
        dispatch(checkForAlreadyLogin());
    }, []);
}

export default useAutoLogin;
