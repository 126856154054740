import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { routes } from './app.routes';
import images from './utils/images.util';
import strings from './utils/string.util';
import NotFound from './pages/NotFound/NotFound.page';
import { NOTFOUND_ROUTE } from './utils/constant.util';

function AppRoutes() {
    function getRedirectedRoute(route) {
        return (
            <Route exact={route.exact} path={route.path}>
                <Redirect to={route.redirectPath} />
            </Route>
        );
    }

    function getNormalRoute({ exact, path, Component }) {
        return (
            <Route
                path={path}
                exact={exact}
                render={props => {
                    return <Component {...props} strings={strings[path]} images={images[path]} />;
                }}
            />
        );
    }

    function getRoutes(routes) {
        return routes.map(route =>
            route.isRedirected
                ? getRedirectedRoute(route)
                : getNormalRoute(route)
        );
    }

    return (
        <Router>
            <Switch>
                {getRoutes(routes)}
                <Route>
                    <NotFound strings={strings[NOTFOUND_ROUTE]} images={images[NOTFOUND_ROUTE]} />
                </Route>
            </Switch>
        </Router>
    );
}

export default AppRoutes;
