import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import './ForgotPassword.css';
import { forgotPasswordSchema } from '../../utils/validation.util';
import Loader from '../../components/Loader/Loader';
import useToast from '../../hooks/useToast';
import { resetPassowordPatch } from '../../utils/global.util';
import useTitle from '../../hooks/useTitle';

function ForgotPasswordPage() {
    var location = useLocation();
    var history = useHistory();
    var [isLoading, setLoading] = useState(false);
    useTitle('Forgot Password | iNeuron.ai');
    var toasts = useToast();
    const { executeRecaptcha } = useGoogleReCaptcha();

    var queryParams = queryString.parse(location.search);
    if (!queryParams.code) {
        history.push('/signin');
        return null;
    }

    async function handleSubmit(data) {
        try {
            setLoading(true);
            var token = await executeRecaptcha();
            await resetPassowordPatch(data.password, queryParams.code, token);
            toasts.showSuccessToast('Password Updated Successfully');
            setTimeout(function () {
                history.push('/signin');
            }, 2000);
        } catch (err) {
            if (err?.response?.data?.error) {
                if (err.response.data.error) {
                    toasts.showFailureToast(err.response.data.error);
                    setTimeout(function () {
                        history.push('/signin');
                    }, 1200);
                } else {
                    toasts.showFailureToast('Something Went Wrong.');
                }
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <section class="reset-password">
                <div class="card">
                    <a href={process.env.REACT_APP_BRAND_DOMAIN}>
                        <img class="company-logo" src="/static/images/ineuron-logo.png" alt="ineuron-logo" />
                    </a>
                    <h4>Reset Your Password</h4>
                    <img class="illustration" src="/static/images/reset-password-illustration.svg" alt="illustration" />
                    <Formik
                        initialValues={{
                            password: '',
                            confirmPassword: '',
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={forgotPasswordSchema}>
                        {({ touched, errors }) => (
                            <Form>
                                <div class="password-input">
                                    <Field
                                        id="new-password"
                                        name="password"
                                        type="password"
                                        placeholder="New Password"
                                    />
                                    {touched.password && <p className="error-field">{errors.password}</p>}
                                    <Field
                                        id="confirm-password"
                                        name="confirmPassword"
                                        type="password"
                                        placeholder="Confirm Password"
                                    />
                                    {touched.confirmPassword && <p className="error-field">{errors.confirmPassword}</p>}
                                    <button type="submit" class="btn btn-primary">
                                        Reset Password
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </React.Fragment>
    );
}

export default ForgotPasswordPage;
