import React from 'react';
import { GoogleLogin as LoginComponent } from 'react-google-login';
import useUserContext from '../../hooks/useUserContext';
import { signInWithGoogle } from '../../store/actions/user.action';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function GoogleLogin({ render }) {
    var { dispatch } = useUserContext();

    function success(data) {
        dispatch(signInWithGoogle(data));
    }

    return (
        <LoginComponent
            clientId={clientId}
            buttonText="Login"
            onSuccess={success}
            onFailure={success}
            render={render}
        />
    );
}

export default GoogleLogin;
