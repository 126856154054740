import React from 'react';

import Modal from '../../../../components/Modal/Modal';
import MobileInput from '../../../../components/MobileInput/MobileInput';
import './PhoneModal.css';
import useUserContext from '../../../../hooks/useUserContext';
import { updatePhoneNumber } from '../../../../store/actions/user.action';
import useMobile from '../../../../hooks/useMobile';
import { getAccessTokenFromStore, getRefreshTokenFromStore } from '../../../../utils/global.util';

function PhoneModal() {
    var { dispatch, user } = useUserContext();
    var { state, error, touched, ...rest } = useMobile();

    function handleSubmit() {
        if (error) return;
        setTimeout(() => {
            dispatch(
                updatePhoneNumber(
                    {
                        phoneNumber: state.mobile,
                        isdCode: state.isdCode,
                    },
                    user?.access_token || getAccessTokenFromStore(),
                    user?.refresh_token || getRefreshTokenFromStore(),
                ),
            );
        }, 500);
    }

    return (
        <Modal
            header="Additional Details"
            body={
                <div className="AdditionDetail">
                    <form>
                        <div className="text-center">
                            <div className="AdditionDetail_UserImg">
                                <img src="/static/images/user-boy.svg" alt="user-icon" />
                            </div>
                            <h6>{user?.firstName}</h6>
                            <MobileInput {...rest} />
                            {touched && error && <div className="AdditionDetail_Error text-center">{error}</div>}
                        </div>
                    </form>
                </div>
            }
            footer={
                <button onClick={handleSubmit} className="btn btn-primary">
                    Proceed
                </button>
            }
        />
    );
}

export default PhoneModal;
