import { useEffect, useRef, useState } from 'react';
import { validatePhone } from '../utils/validation.util';

const INIT_STATE = {
    phoneNumber: '',
    isdCode: '',
    mobile: '',
};

function useMobile({ mobileValidator = validatePhone, initialValue = INIT_STATE } = {}) {
    var [state, setState] = useState({
        phoneNumber: initialValue.phoneNumber,
        isdCode: initialValue.isdCode ? initialValue.isdCode : '+91',
        mobile: initialValue.mobile,
    });
    var [error, setError] = useState(null);
    var [touched, setTouched] = useState(false);
    var [dirty, setDirty] = useState(false);

    var initialVal = useRef(initialValue.phoneNumber);

    useEffect(() => {
        if (initialVal.current === state.phoneNumber) setDirty(false);
    }, [state.phoneNumber]);

    function handleChange(...data) {
        const [phoneWithISOCode, isoData] = data;
        const { dialCode } = isoData;

        if ('+' + dialCode !== state.isdCode) {
            return setState(state => ({
                ...state,
                isdCode: '+' + dialCode,
            }));
        }

        const mobile = phoneWithISOCode.replace(dialCode, '');

        try {
            mobileValidator(mobile);
            setState(state => ({
                ...state,
                phoneNumber: phoneWithISOCode,
                mobile: mobile,
            }));
            setError(null);
        } catch (err) {
            setState(state => ({
                ...state,
                phoneNumber: phoneWithISOCode,
                mobile: mobile,
            }));
            setError(err?.message);
        }
    }

    function handleBlur() {
        setTouched(true);
    }

    function handleKeyDown() {
        setDirty(true);
    }

    return {
        state,
        error,
        touched,
        handleChange,
        handleBlur,
        handleKeyDown,
        dirty,
    };
}

export default useMobile;
