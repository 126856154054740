import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './MobileInput.css';

function MobileInput({ handleChange, handleBlur, phoneNumber, handleKeyDown, containerClass = 'mobile-wrapper' }) {
    return (
        <PhoneInput
            containerClass={`intl-tel-input ${containerClass}`}
            inputClass="input"
            preferredCountries={['in', 'us']}
            onChange={handleChange}
            onBlur={handleBlur}
            value={phoneNumber ? phoneNumber : '91'}
            buttonClass={`${containerClass}-country-search`}
            inputProps={{
                onKeyDown: handleKeyDown,
            }}
            placeholder="Enter Mobile"
        />
    );
}

export default MobileInput;
