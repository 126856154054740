import React from 'react';
import { Link } from 'react-router-dom';
import useTitle from '../../hooks/useTitle';

import { SIGNIN_ROUTE } from '../../utils/constant.util';

import './NotFound.css';

function NotFoundPage({ images }) {
    useTitle('Not Found | iNeuron.ai');

    return (
        <section class="error">
            <img id="top-illustration" src={images.topIllustration} alt="error-illustration" />
            <img id="bottom-illustration" src={images.bottomIllustration} alt="error-illustration" />
            <div class="container">
                <div class="header">
                    <div class="logo">
                        <img src={images.logo} alt="ineuron-logo" />
                    </div>
                </div>
                <div class="error-div flex">
                    <div class="grid">
                        <div class="left">
                            <h1>404 Error</h1>
                            <p>Oops, Looks like this page doesn't exist.</p>
                            <Link to={SIGNIN_ROUTE} class="btn btn-secondary">
                                Back to Home
                            </Link>
                        </div>
                        <div class="right">
                            <div class="icon">
                                <img src={images.notFoundIcon} alt="404-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NotFoundPage;
