import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import setTitle from '../../hoc/withTitle';
import { SIGNUP_ROUTE } from '../../utils/constant.util';
import useAutoLogin from '../../hooks/useAutoLogin';
import PhoneModal from '../Signup/components/PhoneModal/PhoneModal';
import ForgotPasswordModal from '../Signup/components/ForgotPasswordModal/ForgotPasswordModal';
import GoogleLogin from '../../components/GoogleLogin/GoogleLogin';
import AppleLogin from '../../components/AppleLogin/AppleLogin';
import UserContext from '../../context/user.context';
import useRedirect from '../../hooks/useRedirect';
import quotes from '../../data/quotes.json';
import './SignIn.css';
import { getRandomNumberInRange } from '../../utils/global.util';
import { signInWithEmailAndPassword } from '../../store/actions/user.action';
import Loader from '../../components/Loader/Loader';
import { signinSchema } from '../../utils/validation.util';
import useTitle from '../../hooks/useTitle';
import { Helmet } from 'react-helmet';

const randonNumber = getRandomNumberInRange(0, 150);
const quote = quotes[randonNumber];

function SigninPage({ images, strings }) {
    var [isForgotPasswordModalOpen, setForgotPasswordModal] = useState(false);

    var [showPassword, setShowPassword] = useState(false);
    useAutoLogin();
    var { isLoggedIn, isLoading, showPhoneModal, dispatch, isInitialCheckDone } = useContext(UserContext);
    var { redirect } = useRedirect();
    const history = useHistory();

    useTitle('Sign In | iNeuron.ai');

    if (isLoggedIn) {
        redirect(history);
        return null;
    }

    let backgroundImage = images.backgroundImage;
    var leftSide = {
        backgroundImage: `url(${backgroundImage})`,
    };

    function handleSubmit(data) {
        document.querySelectorAll('input').forEach(ele => {
            ele.blur();
        });
        if (isLoading) return;
        setTimeout(() => {
            dispatch(signInWithEmailAndPassword(data));
        }, 500);
    }

    if (!isInitialCheckDone) return <Loader />;

    return (
        <React.Fragment>
            <Helmet>
                <title>Sign In | iNeuron.ai</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            {isLoading && <Loader />}
            <div className="sign-in flex">
                <div className="left-side flex" style={leftSide}>
                    <div className="glass">
                        <div className="logo">
                            <a href={process.env.REACT_APP_BRAND_DOMAIN}>
                                <img src={images.logo} alt="ineuron-logo" />
                            </a>
                        </div>
                        <h3>{strings.heading}</h3>
                        <p>{strings.subheading}</p>
                        <div className="quote">
                            <p>{quote.content}</p>
                            <span>{quote.author}</span>
                        </div>
                    </div>
                </div>
                <div className="right-side flex">
                    <div>
                        <h1>{strings.title}</h1>
                        <div>
                            <GoogleLogin
                                render={props => (
                                    <button className="btn btn-light-filled-outline google" onClick={props.onClick}>
                                        <img src={images.googleBtn} alt="google" /> {strings.googleBtn}
                                    </button>
                                )}
                            />
                        </div>
                        <div>
                            <AppleLogin
                                render={props => (
                                    <button className="btn btn-light-filled-outline apple" onClick={props.onClick}>
                                        <img src={images.appleBtn} alt="apple" /> {strings.appleBtn}
                                    </button>
                                )}
                            />
                        </div>
                        <div className="divider">
                            <div className="line"></div>
                            <span>{strings.or}</span>
                        </div>
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            onSubmit={handleSubmit}
                            validationSchema={signinSchema}>
                            {({ errors, touched }) => {
                                return (
                                    <Form>
                                        <Field type="email" placeholder={strings.emailPlaceholder} name="email" />
                                        {touched.email && <p className="error-field">{errors.email}</p>}
                                        <div className="password-div">
                                            <Field
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder={strings.passwordPlaceholder}
                                                name="password"
                                            />
                                            {touched.password && <p className="error-field">{errors.password}</p>}
                                            {showPassword ? (
                                                <img
                                                    id="password-show"
                                                    src={images.showPassword}
                                                    alt="password-show"
                                                    onClick={() => setShowPassword(false)}
                                                />
                                            ) : (
                                                <img
                                                    id="password-hidden"
                                                    src={images.hidePassword}
                                                    alt="password-hidden"
                                                    onClick={() => setShowPassword(true)}
                                                />
                                            )}
                                        </div>

                                        <div className="links flex">
                                            <label htmlFor="remember-web">
                                                <input type="checkbox" id="remember-web" />
                                                <span>{strings.remember}</span>
                                            </label>
                                            <span
                                                onClick={() => setForgotPasswordModal(true)}
                                                id="forgot-password-text">
                                                {strings.forgot}
                                            </span>
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            {strings.submitBtn}
                                        </button>
                                    </Form>
                                );
                            }}
                        </Formik>
                        <div className="text-center end">
                            <span>
                                {strings.dontHaveAccount} <Link to={SIGNUP_ROUTE}>{strings.signUp}</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {showPhoneModal && <PhoneModal />}
            {isForgotPasswordModalOpen && <ForgotPasswordModal close={() => setForgotPasswordModal(false)} />}

            <div className="sign-in-mobile flex">
                <div className="mobile_wrapper">
                    <div className="login_logo text-center">
                        <a href={process.env.REACT_APP_BRAND_DOMAIN} rel="noreferrer">
                            <img src={images.logo} alt="ineuron-logo" />
                        </a>
                    </div>
                    <h1>Sign In</h1>
                    <div>
                        <GoogleLogin
                            render={props => (
                                <button className="btn btn-light-filled-outline google" onClick={props.onClick}>
                                    <img src={images.googleBtn} alt="google" /> {strings.googleBtn}
                                </button>
                            )}
                        />
                    </div>
                    <div>
                        <AppleLogin
                            render={props => (
                                <button className="btn btn-light-filled-outline apple" onClick={props.onClick}>
                                    <img src={images.appleBtn} alt="apple" /> {strings.appleBtn}
                                </button>
                            )}
                        />
                    </div>
                    {/* <FacebookLogin
                        render={renderProps => (
                            <button className="btn btn-light-filled-outline facebook" onClick={renderProps.onClick}>
                                <img src={images.facebookBtn} alt="facebook" /> {strings.facebookBtn}
                            </button>
                        )}
                    /> */}
                    <div className="divider">
                        <div className="line"></div>
                        <span>{strings.or}</span>
                    </div>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        onSubmit={handleSubmit}
                        validationSchema={signinSchema}>
                        {({ errors, touched }) => (
                            <Form>
                                <Field name="email" type="email" placeholder="Email Address" />
                                {touched.email && errors.email && <p className="error-field">{errors.email}</p>}
                                <div className="password-div">
                                    <Field
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Password"
                                        name="password"
                                    />
                                    {showPassword ? (
                                        <img
                                            id="password-show"
                                            src={images.showPassword}
                                            alt="password-show"
                                            onClick={() => setShowPassword(false)}
                                        />
                                    ) : (
                                        <img
                                            id="password-hidden"
                                            src={images.hidePassword}
                                            alt="password-hidden"
                                            onClick={() => setShowPassword(true)}
                                        />
                                    )}
                                </div>
                                {touched.password && errors.password && (
                                    <p className="error-field">Password is required</p>
                                )}
                                <div className="links flex">
                                    <label htmlFor="remember-mobile">
                                        <input type="checkbox" id="remember-mobile" />
                                        <span>Remember Me</span>
                                    </label>
                                    <span id="forgot-password-text" onClick={() => setForgotPasswordModal(true)}>
                                        {'Forgot Password'}
                                    </span>
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Sign In
                                </button>
                            </Form>
                        )}
                    </Formik>
                    <div className="text-center end">
                        <span>
                            Don't have a account? <Link to={SIGNUP_ROUTE}>{strings.signUp}</Link>
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default setTitle('Sign In | iNeuron.ai')(SigninPage);
