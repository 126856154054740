import PropTypes from 'prop-types';

import './Modal.css';

function Modal({ header, body, footer, close }) {
    return (
        <div className="Modal Modal_Center">
            <div className="Modal_Content Modal_Card card">
                <div className="Modal_Content__Header">
                    {header}
                    {close && <i className="fas fa-times" onClick={close}></i>}
                </div>
                <div className="Modal_Content__Body">{body}</div>
                <div className="Modal_Content__Footer">{footer}</div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    header: PropTypes.string,
    body: PropTypes.element,
    footer: PropTypes.element,
};

export default Modal;
