export const PROFILE_ROUTE = '/profile';
export const SIGNIN_ROUTE = '/signin';
export const SIGNUP_ROUTE = '/signup';
export const HOME_ROUTE = '/';
export const OAUTH_ROUTE = '/oauth';
export const FORGOTPASSWORD_ROUTE = '/reset-password';
export const SERVERERROR_ROUTE = '/server-error';
export const NOTFOUND_ROUTE = '/notfound';
export const VERIFY_EMAIL = '/verify-email';

export const COURSE_DOMAIN = 'http://localhost:3000';
export const LEARN_DOMAIN = 'https://learn-ineuron.netlify.app';
export const INTERNSHIP_DOMAIN = 'https://internship-ineuron.netlify.app';
export const REFRESH_TOKEN = '89kjhsd1lk2';
export const ACCESS_TOKEN = '73hjv56dgs1';
export const RECAPTCHA_TOKEN = '43fgfas3234';
export const SIGNUP_URL = '';
export const FORGOTPASSWORD_URL = '';
export const urlRegex = new RegExp(
    '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
);

export const FETCHQUOTE_URL = 'https://api.quotable.io/random';
