import * as constants from '../utils/constant.util';

const strings = {
    [constants.SIGNIN_ROUTE]: {},
    [constants.OAUTH_ROUTE]: {},
    [constants.HOME_ROUTE]: {},
};

const common = {
    forgot: 'Forgot Password?',
    title: 'Sign In',
    heading: 'Hey There, Welcome!',
    subheading: 'We are iNeuron Technology',
    content:
        'If you accept the expectations of others, especially negative ones, then you never will change the outcome.',
    googleBtn: 'Continue with Google',
    appleBtn: 'Continue with Apple',
    facebookBtn: 'Continue with Facebook',
    or: 'Or',
    author: 'Michael Jordan',
    emailPlaceholder: 'Email Address',
    passwordPlaceholder: 'Enter Password',
    dontHaveAccount: "Don't have a account?",
    remember: 'Remember Me',
    submitBtn: 'Sign In',
    signUp: 'Sign Up',
};

const finalString = Object.keys(strings).reduce((acc, key) => {
    return { ...acc, [key]: { ...strings[key], ...common } };
}, {});

export default finalString;
