import AppRoutes from './AppRoutes';
import { UserProvider } from './context/user.context';
import Toast from './components/Toast/Toast';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY}>
            <UserProvider>
                <AppRoutes />
                <Toast />
            </UserProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
