import useUserContext from "../hooks/useUserContext";
import { useLocation, Redirect } from 'react-router-dom';
import { checkForAlreadyLogin } from "../store/actions/user.action";
import { useEffect } from "react";
import { SIGNIN_ROUTE } from "../utils/constant.util";
import Loader from "../components/Loader/Loader";

function withAuth(Component) {

    return function AuthProtector(props){
        var { dispatch, isLoggedIn, isInitialCheckDone } = useUserContext();
        var location = useLocation();
        var redirectObj = {
            pathname: SIGNIN_ROUTE,
            search: `redirectUrl=${location.pathname}`,
        };



        useEffect(() => {
            if(!isInitialCheckDone) dispatch(checkForAlreadyLogin());
        }, []);
        
        if(isInitialCheckDone && !isLoggedIn) {
            return <Redirect to={redirectObj} />
        }

        if(isLoggedIn) return <Component {...props} />

        return <Loader />
    }
}

export default withAuth;