import React, { useState } from 'react';

import './EmailVerification.css';
import Loader from '../Loader/Loader';
import useToast from '../../hooks/useToast';
import { sendEmailVerificationLink } from '../../utils/global.util';

function EmailVerification({ close }) {
    var [isLoading, setLoading] = useState(false);
    var toasts = useToast();

    async function handleClick() {
        try {
            setLoading(true);
            await sendEmailVerificationLink();
            toasts.showSuccessToast('Verification Link Send Successfully');
            close();
        } catch (e) {
            toasts.showFailureToast('Something went wrong, Please try again later');
        } finally {
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="Email">
                <div className="container email_wrapper_flex">
                    <p className="Email_content">
                        Your Email hasn't been verified yet.{' '}
                        <span className="Email_action" onClick={handleClick}>
                            Click Here{' '}
                        </span>
                        to get the Verification Link.
                    </p>
                    <p className="Email_close" onClick={close}>
                        <i className="fa fa-times"></i>
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EmailVerification;
