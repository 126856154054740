import {
    START_REQUEST,
    SIGNIN_WITH_EMAIL_SUCCESS,
    SIGNIN_WITH_EMAIL_FAILURE,
    SIGNIN_WITH_GOOGLE_FAILURE,
    SIGNIN_WITH_APPLE_FAILURE,
    AUTO_LOGIN_CHECK_SUCCESS,
    AUTO_LOGIN_CHECK_FAILURE,
    SIGNIN_WITH_GOOGLE_SUCCESS,
    SIGNIN_WITH_APPLE_SUCCESS,
    UPDATE_PHONE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    SIGNUP_WITH_EMAIL_SUCCESS,
    SIGNUP_WITH_EMAIL_FAILURE,
    LOGOUT,
    DELETE_ACCOUNT,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PHONE_FAILURE,
    INIT_CHECK_DONE,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,
} from '../actions/user.constant';

function reducer(state, action) {
    let user;
    switch (action.type) {
        case START_REQUEST:
            return { ...state, isLoading: true };
        case SIGNIN_WITH_EMAIL_SUCCESS:
        case SIGNUP_WITH_EMAIL_SUCCESS:
            user = action.user;
            if (user.phoneNumber) return { ...state, isLoggedIn: true, isLoading: false, user: user };
            return { ...state, isLoading: false, isLoggedIn: false, user: action.user, showPhoneModal: true };
        case INIT_CHECK_DONE:
            return { ...state, isInitialCheckDone: true };
        case AUTO_LOGIN_CHECK_SUCCESS:
            user = action.user;
            if (user.phoneNumber) return { ...state, isLoggedIn: true, isLoading: false, user: user };
            return {
                ...state,
                isLoggedIn: false,
                isLoading: false,
                user: action.user,
                showPhoneModal: true,
                isInitialCheckDone: true,
            };
        case SIGNIN_WITH_GOOGLE_SUCCESS:
        case SIGNIN_WITH_APPLE_SUCCESS:
            user = action.user;
            if (user.phoneNumber) return { ...state, isLoggedIn: true, isLoading: false, user: user };
            return { ...state, isLoggedIn: false, isLoading: false, user: action.user, showPhoneModal: true };
        case UPDATE_PHONE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                user: { ...state.user, ...action.mobile, showPhoneModal: false },
            };
        case UPLOAD_IMAGE_SUCCESS:
            return { ...state, isLoading: false, user: { ...state.user, img: action.payload } };
        case UPDATE_PROFILE_SUCCESS:
            return { ...state, isLoading: false, user: { ...state.user, ...action.user } };
        case SIGNIN_WITH_EMAIL_FAILURE:
        case SIGNUP_WITH_EMAIL_FAILURE:
        case SIGNIN_WITH_GOOGLE_FAILURE:
        case SIGNIN_WITH_APPLE_FAILURE:
        case AUTO_LOGIN_CHECK_FAILURE:
        case DELETE_ACCOUNT:
            return { ...state, isLoggedIn: false, isLoading: false, isInitialCheckDone: true };
        case UPDATE_PROFILE_FAILURE:
        case UPLOAD_IMAGE_FAILURE:
        case UPDATE_PHONE_FAILURE:
            return { ...state, isLoading: false };
        case LOGOUT:
            return { ...state, isLoggedIn: false, isInitialCheckDone: true, isLoading: false };
        default:
            return state;
    }
}

export default reducer;
