import * as constants from '../utils/constant.util';

const publicURL = process.env.PUBLIC_URL;
const imageUrl = `${publicURL}/static/images`;

const images = {
    [constants.SIGNIN_ROUTE]: {},
    [constants.HOME_ROUTE]: {},
    [constants.SIGNUP_ROUTE]: {},
    [constants.PROFILE_ROUTE]: {},
    [constants.OAUTH_ROUTE]: {},
    [constants.NOTFOUND_ROUTE]: {
        notFoundIcon: 'notfound/404-icon.svg',
    },
    [constants.SERVERERROR_ROUTE]: {
        serverErrorIcon: 'server-error/503-icon.svg',
    },
};

const common = {
    logo: 'ineuron-logo.png',
    backgroundImage: 'background-illustration.png',
    backgroundImageLight: 'background-illustration-light.png',
    googleBtn: 'google-colored.svg',
    appleBtn: 'apple-colored.svg',
    facebookBtn: 'facebook-colored.svg',
    showPassword: 'password-show.svg',
    hidePassword: 'password-hidden.svg',
    topIllustration: 'error-page-illustration.svg',
    bottomIllustration: 'error-page-illustration.svg',
};

const commonWithPublicPath = Object.keys(common).reduce((acc, key) => {
    return { ...acc, [key]: `${imageUrl}/${common[key]}` };
}, {});

const imagesWithPublicPath = Object.keys(images).reduce((acc, imagePageKey) => {
    var obj = {};
    var imageObj = images[imagePageKey];
    Object.keys(imageObj).forEach(key => {
        obj[key] = `${imageUrl}/${imageObj[key]}`;
    });
    return { ...acc, [imagePageKey]: { ...obj, ...commonWithPublicPath } };
}, {});

export default imagesWithPublicPath;
