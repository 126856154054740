import {
    renewAccessToken,
    generateAuthToken,
    signin,
    userDetails,
    signup,
    socialLogin as socialLoginApi,
    updatePhoneNumber,
    resetPassword,
    updateProfilePic,
    updateProfile,
    sendEmailVerification,
    verifyEmail,
    logoutApi,
    deleteAccount,
} from './api.util';
import { userAxios, authAxios, defaultAxios } from './axios.util';
import { REFRESH_TOKEN, ACCESS_TOKEN, FETCHQUOTE_URL, RECAPTCHA_TOKEN, PROFILE_ROUTE, urlRegex } from './constant.util';
import axios from 'axios';
import DOMPurify from 'dompurify';

export const getRefreshTokenFromStore = () => window.localStorage.getItem(REFRESH_TOKEN);
export const setRefreshTokenToStore = refreshToken => window.localStorage.setItem(REFRESH_TOKEN, refreshToken);

export const getRecaptchaTokenFromStore = () => window.localStorage.getItem(RECAPTCHA_TOKEN);
export const setRecaptchaTokenToStore = token => window.localStorage.setItem(RECAPTCHA_TOKEN, token);

export const getAccessTokenFromStore = () => window.localStorage.getItem(ACCESS_TOKEN);
export const setAccessTokenToStore = token => window.localStorage.setItem(ACCESS_TOKEN, token);

export const clearAccessToken = () => window.localStorage.removeItem(ACCESS_TOKEN);

export const clearRefreshToken = () => window.localStorage.removeItem(REFRESH_TOKEN);

export const logoutAction = () => {
    userAxios.post(logoutApi);
};

export const deleteAccountDetails = token => {
    userAxios.delete(deleteAccount, {
        data: {
            'g-recaptcha-response': token,
        },
    });
};

export const getNewAccessToken = async refreshToken => {
    var response = await userAxios.post(renewAccessToken, {
        token: refreshToken,
    });
    return response.data.access_token;
};
export const getIntermediateAuthToken = async (oAuth, tokenFailure) => {
    try {
        let response;
        if (oAuth) {
            response = await userAxios.post(generateAuthToken + '?type=oauth');
        } else {
            response = await userAxios.post(generateAuthToken);
        }
        return response.data.token;
    } catch (err) {
        tokenFailure(err?.response?.data?.error);
        return Promise.reject(err);
    }
};

const isEncoded = uri => {
    uri = uri || '';
    return uri !== decodeURIComponent(uri);
};

export const getRedirectData = () => {
    var obj = {
        sameDomain: true,
        redirectUrl: '',
    };

    const queryData = {
        domain: null,
        redirectUrl: null,
    };
    const websiteUrlParam = window.location.search;
    websiteUrlParam.split('?').forEach(param => {
        param.split('&').forEach(param => {
            if (param !== '') {
                const key = param.split('=')[0];
                const value = param.split('=')[1];
                queryData[key] = value;
            }
        });
    });

    const availableDomains = process.env.REACT_APP_DOMAINS.split(',');
    const oauthDomains = process.env.REACT_APP_OAUTH_DOMAIN.split(',');

    if (queryData.domain) {
        if (queryData.domain.includes('ineuron.ai')) {
            if (availableDomains.includes(queryData.domain) || oauthDomains.includes(queryData.domain)) {
                obj.sameDomain = false;
                if (
                    queryData.domain === 'ineuron.ai' ||
                    queryData.domain === 'staging.ineuron.ai' ||
                    queryData.domain === 'dev.ineuron.ai' ||
                    queryData.domain === 'jobs.ineuron.ai' ||
                    queryData.domain === 'jobs-staging.ineuron.ai' ||
                    queryData.domain === 'jobs-dev.ineuron.ai'
                ) {
                    obj.domain = `https://${queryData.domain}/callback`;
                } else {
                    obj.domain = `https://${queryData.domain}`;
                }
            }
        }

        if (queryData.domain.includes('localhost')) {
            obj.sameDomain = false;
            obj.domain = `http://${decodeURIComponent(queryData.domain)}`;
        }
    }

    if (queryData.redirectUrl) {
        const url = decodeURIComponent(queryData.redirectUrl);
        if (!urlRegex.test(url)) {
            const sanitizeUrl = DOMPurify.sanitize(url);
            if (isEncoded(queryData.redirectUrl)) {
                obj.redirectUrl = encodeURIComponent(sanitizeUrl);
            } else {
                obj.redirectUrl = sanitizeUrl;
            }
        }
    }

    if (obj.sameDomain) {
        obj.redirectUrl = PROFILE_ROUTE;
    }

    return obj;
};

getRedirectData();

export const signInWithEmailAndPassword = async (email, password, token) => {
    var response = await authAxios.post(signin, {
        email,
        password,
        'g-recaptcha-response': token,
    });
    return response.data;
};

export const signupWithEmail = async (data, token) => {
    var response = await authAxios.post(signup, {
        ...data,
        'g-recaptcha-response': token,
    });
    return response.data;
};

export const getQuote = async () => {
    var response = await axios.get(FETCHQUOTE_URL);
    var { content, author } = response.data;
    return {
        content,
        author,
    };
};

export const getUserDetails = () => userAxios.get(userDetails);

export const getUserDetailsWithToken = accessToken => {
    return defaultAxios.get(userDetails, {
        headers: { Authorization: 'Bearer ' + accessToken, 'X-RECAPTCHA-SIGNATURE': 'text' },
    });
};

export const socialLogin = async (provider, token, captchaToken, data) => {
    var response;
    if (data) {
        response = await authAxios.post(socialLoginApi, {
            provider: provider,
            token: token,
            data,
            'g-recaptcha-response': captchaToken,
        });
    } else {
        response = await authAxios.post(socialLoginApi, {
            provider: provider,
            token: token,
            'g-recaptcha-response': captchaToken,
        });
    }
    return response.data;
};

export const updatePhone = async (data, accessToken, token) => {
    var response = await defaultAxios.patch(
        updatePhoneNumber,
        {
            ...data,
            'g-recaptcha-response': token,
        },
        {
            headers: { Authorization: 'Bearer ' + accessToken },
        },
    );
    return response.data;
};

export const sendResetPasswordLink = async (email, token) => {
    var response = await authAxios.post(resetPassword, { email: email, 'g-recaptcha-response': token });
    return response.data;
};

export const sendEmailVerificationLink = async () => {
    var response = await userAxios.post(sendEmailVerification, {});
    return response.data;
};

export const resetPassowordPatch = async (password, code, token) => {
    var response = await authAxios.patch(resetPassword + '/' + code, { password, 'g-recaptcha-response': token });
    return response.data;
};

export const getRandomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
};

export const clearTokens = () => {
    clearAccessToken();
    clearRefreshToken();
};

export const updateProfilePicture = async profile => {
    var response = await userAxios.patch(updateProfilePic, profile);
    return response.data;
};

export const updateUserProfile = async (profile, token) => {
    var response = await userAxios.patch(updateProfile, {
        ...profile,
        'g-recaptcha-response': token,
    });
    return response.data;
};

export const verifyEmailCode = async code => {
    var response = await userAxios.post(verifyEmail + '/' + code, {});
    return response.data;
};

export function sanitizeObject(obj, compareObj) {
    let isChanged = false;
    let val = {};

    Object.keys(compareObj).forEach(key => {
        if (typeof compareObj[key] === 'object') {
            let [changed, value] = sanitizeObject(obj[key], compareObj[key]);
            if (changed) {
                isChanged = true;
                val = { ...val, [key]: value };
            }
        } else {
            if (key === 'phoneNumber' && obj[key]) {
                if (!obj[key].includes(compareObj[key])) {
                    isChanged = true;
                    val = { ...val, [key]: obj[key] };
                }
            } else if (compareObj[key] !== obj[key]) {
                isChanged = true;
                val = { ...val, [key]: obj[key] };
            }
        }
    });

    return [isChanged, val];
}

export const loadScriptByUrl = (id, url) => {
    return new Promise((resolve, reject) => {
        let isScriptExist = document.getElementById(id);
        if (isScriptExist) return resolve();

        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
            resolve();
        };
        document.body.appendChild(script);
    });
};
