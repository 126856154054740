import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useToast from '../../hooks/useToast';
import queryString from 'query-string';
import useTitle from '../../hooks/useTitle';
import Loader from '../../components/Loader/Loader';

import './VerifyEmail.css';
import { verifyEmailCode } from '../../utils/global.util';

function VerifyEmail() {
    var location = useLocation();
    var history = useHistory();
    useTitle('Verify Email | iNeuron.ai');
    var toasts = useToast();

    var queryParams = queryString.parse(location.search);

    useEffect(() => {
        (async function () {
            if (queryParams.code) {
                try {
                    await verifyEmailCode(queryParams.code);
                    toasts.showSuccessToast('Email verified');
                } catch (err) {
                    toasts.showFailureToast('Something Went Wrong');
                } finally {
                    setTimeout(() => {
                        history.push('/signin');
                    }, 2000);
                }
            }
        })();
    }, []);

    if (!queryParams.code) return history.push('/signin');

    return (
        <React.Fragment>
            <Loader />
            <div className="VerifyEmail">
                <p className="VerifyEmail_text">Verifying your email. Hold tight.</p>
            </div>
        </React.Fragment>
    );
}

export default VerifyEmail;
