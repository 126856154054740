import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import useUserContext from '../../hooks/useUserContext';
import { signInWithApple } from '../../store/actions/user.action';

const clientId = process.env.REACT_APP_APPLE_CLIENT_ID;

function AppleLogin({ render }) {
    var { dispatch } = useUserContext();

    function success(data) {
        dispatch(signInWithApple(data?.authorization?.code, data?.user?.name));
    }

    return (
        <AppleSignin
            authOptions={{
                clientId,
                scope: 'email name',
                redirectURI: `${window.location.origin}/callback/apple`,
                // redirectURI: 'https://account-staging.ineuron.ai/callback/apple',
                state: 'state',
                nonce: 'nonce',
                usePopup: true,
            }}
            onSuccess={success}
            onError={success}
            render={render}
        />
    );
}

export default AppleLogin;
