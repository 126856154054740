import React, { useRef, useState } from 'react';

import Modal from '../../../../components/Modal/Modal';
import { sendResetPasswordLink } from '../../../../utils/global.util';
import { validateEmail } from '../../../../utils/validation.util';
import Loader from '../../../../components/Loader/Loader';
import useToast from '../../../../hooks/useToast';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './ForgotPassword.css';

function ForgotPasswordModal({ close }) {
    var input = useRef();
    const [errors, setErrors] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const toasts = useToast();
    const { executeRecaptcha } = useGoogleReCaptcha();

    async function handleSubmit() {
        var value = input.current.value;
        try {
            validateEmail(value);
            setLoading(true);
            var token = await executeRecaptcha();
            await sendResetPasswordLink(value, token);
            toasts.showSuccessToast('Email sent successfully');
            input.current.value = null;
        } catch (err) {
            if (err?.response?.data?.error) {
                toasts.showFailureToast(err?.response?.data?.error);
                return;
            }
            setErrors(err.message);
        } finally {
            setLoading(false);
        }
    }

    function handleChange(e) {
        try {
            var value = e.target.value;
            validateEmail(value);
            setErrors(null);
        } catch (err) {
            setErrors(err.message);
        }
    }

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <Modal
                close={close}
                header="Forgot Password"
                body={
                    <div className="ForgotPassword">
                        <input
                            ref={input}
                            type="email"
                            placeholder="Enter your registered email"
                            onChange={handleChange}
                            required
                        />
                        {errors && <div className="ForgotPassword_Error text-center">{errors}</div>}
                    </div>
                }
                footer={
                    <button onClick={handleSubmit} className="btn btn-primary">
                        Submit
                    </button>
                }
            />
        </React.Fragment>
    );
}

export default ForgotPasswordModal;
