export const generateAuthToken = '/auth/token/generate';
export const renewAccessToken = '/auth/renewAccessToken';
export const oauth = '/auth/phpLogin';
export const signin = '/auth/login';
export const userDetails = 'auth/profile';
export const signup = '/auth/signup';
export const socialLogin = '/auth/socialLogin';
export const updatePhoneNumber = '/auth/profile/phoneNumber';
export const resetPassword = '/auth/resetPassword';
export const sendEmailVerification = '/auth/sendVerificationEmail';
export const verifyEmail = '/auth/verifyEmail';
export const updateProfilePic = '/auth/profile/profilePicture';
export const updateProfile = '/auth/profile';
export const logoutApi = '/auth/logout';
export const deleteAccount = '/auth/profile/deleteAccount';
