import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';

import useAutoLogin from '../../hooks/useAutoLogin';
import useUserContext from '../../hooks/useUserContext';
import { Link, useHistory } from 'react-router-dom';
import MobileInput from '../../components/MobileInput/MobileInput';
import quotes from '../../data/quotes.json';
import { getRandomNumberInRange } from '../../utils/global.util';
import GoogleLogin from '../../components/GoogleLogin/GoogleLogin';
import AppleLogin from '../../components/AppleLogin/AppleLogin';
import PhoneModal from '../Signup/components/PhoneModal/PhoneModal';
import Loader from '../../components/Loader/Loader';
import { signupSchema } from '../../utils/validation.util';
import { signUpWithEmail } from '../../store/actions/user.action';
import useTitle from '../../hooks/useTitle';
import useRedirect from '../../hooks/useRedirect';
import useMobile from '../../hooks/useMobile';
import { SIGNIN_ROUTE } from '../../utils/constant.util';
import { Helmet } from 'react-helmet';

const randonNumber = getRandomNumberInRange(0, 150);
const quote = quotes[randonNumber];

function SignupPage({ images }) {
    var [showPassword, setShowPassword] = useState(false);
    var [showConfirmPassword, setShowConfirmPassword] = useState(false);
    var { redirect } = useRedirect();
    const history = useHistory();

    useTitle('Sign Up | iNeuron.ai');

    useAutoLogin();
    var { isLoggedIn, isLoading, dispatch, showPhoneModal } = useUserContext();
    var { state, error, ...rest } = useMobile();

    if (isLoggedIn) {
        redirect(history);
        return null;
    }

    function onSubmit(data) {
        if (error) return;

        setTimeout(() => {
            dispatch(
                signUpWithEmail({
                    ...data,
                    phoneNumber: state.mobile,
                    isdCode: state.isdCode,
                }),
            );
        }, 500);
    }

    let backgroundImage = images.backgroundImage;
    var leftSide = {
        backgroundImage: `url(${backgroundImage})`,
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Sign Up | iNeuron.ai</title>
                <meta name="title" content="Sign Up | iNeuron.ai" />
                <meta name="description" content="Register yourself with iNeuron to enrol on the latest courses." />
                <meta name="robots" content="noindex, nofollow" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://account.ineuron.ai/signup" />
                <meta property="og:title" content="Sign Up | iNeuron.ai" />
                <meta
                    property="og:description"
                    content="Register yourself with iNeuron to enrol on the latest courses."
                />
                <meta property="og:image" content="/static/account-sign-up-thumbnail.png" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://account.ineuron.ai/signup" />
                <meta property="twitter:title" content="Sign Up | iNeuron.ai" />
                <meta
                    property="twitter:description"
                    content="Register yourself with iNeuron to enrol on the latest courses."
                />
                <meta property="twitter:image" content="/static/account-sign-up-thumbnail.png" />
            </Helmet>
            {isLoading && <Loader />}
            <div className="sign-up flex">
                <div className="left-side flex" style={leftSide}>
                    <div className="glass">
                        <div className="logo">
                            <a href={process.env.REACT_APP_BRAND_DOMAIN}>
                                <img src={images.logo} alt="ineuron-logo" />
                            </a>
                        </div>
                        <h3>Hey There, Welcome!</h3>
                        <p>We are iNeuron Technology</p>
                        <div className="quote">
                            <p>{quote.content}</p>
                            <span>- {quote.author}</span>
                        </div>
                    </div>
                </div>

                <div className="right-side flex">
                    <div>
                        <h1>Sign Up</h1>
                        <div>
                            <GoogleLogin
                                render={props => (
                                    <button className="btn btn-light-filled-outline google" onClick={props.onClick}>
                                        <img src={images.googleBtn} alt="google" /> Continue with Google
                                    </button>
                                )}
                            />
                        </div>
                        <div>
                            <AppleLogin
                                render={props => (
                                    <button className="btn btn-light-filled-outline apple" onClick={props.onClick}>
                                        <img src={images.appleBtn} alt="apple" /> Continue with Apple
                                    </button>
                                )}
                            />
                        </div>
                        <div className="divider">
                            <div className="line"></div>
                            <span>Or</span>
                        </div>
                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                password: '',
                                confirmPassword: '',
                                tandc: '',
                            }}
                            onSubmit={onSubmit}
                            validationSchema={signupSchema}>
                            {({ errors, handleChange, handleBlur, values, touched }) => {
                                return (
                                    <Form>
                                        <div className="name flex">
                                            <div
                                                className="flex"
                                                style={{
                                                    width: '100%',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                }}>
                                                <Field type="text" placeholder="First Name" name="firstName" />
                                                {touched.firstName && <p className="error-field">{errors.firstName}</p>}
                                            </div>
                                            <div
                                                className="flex"
                                                style={{
                                                    width: '100%',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                }}>
                                                <Field type="text" placeholder="Last Name" name="lastName" />
                                                {touched.lastName && <p className="error-field">{errors.lastName}</p>}
                                            </div>
                                        </div>
                                        <Field type="email" placeholder="Email Address" name="email" />
                                        {touched.email && <p className="error-field">{errors.email}</p>}
                                        <MobileInput {...rest} {...state} />
                                        {rest.touched && error && <p className="error-field">{error}</p>}
                                        <div className="password-container flex">
                                            <div className="password-div">
                                                <Field
                                                    id="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Create Password"
                                                    name="password"
                                                />
                                                {touched.password && <p className="error-field">{errors.password}</p>}
                                                {showPassword ? (
                                                    <img
                                                        id="password-show"
                                                        src={images.showPassword}
                                                        alt="password-show"
                                                        onClick={() => setShowPassword(false)}
                                                    />
                                                ) : (
                                                    <img
                                                        id="password-hidden"
                                                        src={images.hidePassword}
                                                        alt="password-hidden"
                                                        onClick={() => setShowPassword(true)}
                                                    />
                                                )}
                                            </div>
                                            <div className="password-div">
                                                <Field
                                                    id="confirm-password"
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    placeholder="Confirm Password"
                                                    name="confirmPassword"
                                                />
                                                {touched.confirmPassword && (
                                                    <p className="error-field">{errors.confirmPassword}</p>
                                                )}
                                                {showConfirmPassword ? (
                                                    <img
                                                        id="password-show"
                                                        src={images.showPassword}
                                                        alt="password-show"
                                                        onClick={() => setShowConfirmPassword(false)}
                                                    />
                                                ) : (
                                                    <img
                                                        id="password-hidden"
                                                        src={images.hidePassword}
                                                        alt="password-hidden"
                                                        onClick={() => setShowConfirmPassword(true)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="terms">
                                            <label htmlFor="terms">
                                                <Field type="checkbox" id="terms" name="tandc" />
                                                <span>
                                                    Agree to our
                                                    <a
                                                        href={process.env.REACT_APP_TERMS}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        {' '}
                                                        Terms and Conditions
                                                    </a>
                                                </span>
                                            </label>
                                        </div>
                                        {touched.tandc && <p className="error-field">{errors.tandc}</p>}
                                        <button type="submit" className="btn btn-primary">
                                            Sign Up
                                        </button>
                                    </Form>
                                );
                            }}
                        </Formik>
                        <div className="text-center end">
                            <span>
                                Already have a account? <Link to="/signin">Sign In</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {showPhoneModal && <PhoneModal />}
            <div class="sign-up-mobile flex">
                <div>
                    <div class="login_logo text-center">
                        <a href={process.env.REACT_APP_BRAND_DOMAIN} rel="noreferrer">
                            <img src={images.logo} alt="ineuron-logo" />
                        </a>
                    </div>
                    <h1>Sign Up</h1>
                    <div>
                        <GoogleLogin
                            render={props => (
                                <button className="btn btn-light-filled-outline google" onClick={props.onClick}>
                                    <img src={images.googleBtn} alt="google" /> Continue with Google
                                </button>
                            )}
                        />
                    </div>
                    <div>
                        <AppleLogin
                            render={props => (
                                <button className="btn btn-light-filled-outline apple" onClick={props.onClick}>
                                    <img src={images.appleBtn} alt="apple" /> Continue with Apple
                                </button>
                            )}
                        />
                    </div>
                    <div class="divider">
                        <div class="line"></div>
                        <span>Or</span>
                    </div>
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            password: '',
                            confirmPassword: '',
                            tandc: '',
                        }}
                        onSubmit={onSubmit}
                        validationSchema={signupSchema}>
                        {({ errors, touched }) => (
                            <Form>
                                <div class="name flex">
                                    <div
                                        className="flex"
                                        style={{
                                            width: '100%',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}>
                                        <Field type="text" placeholder="First Name" name="firstName" />
                                        {touched.firstName && <p className="error-field">{errors.firstName}</p>}
                                    </div>
                                    <div
                                        className="flex"
                                        style={{
                                            width: '100%',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}>
                                        <Field type="text" placeholder="Last Name" name="lastName" />
                                        {touched.lastName && <p className="error-field">{errors.lastName}</p>}
                                    </div>
                                </div>
                                <Field type="email" placeholder="Email Address" name="email" />
                                {touched.email && <p className="error-field">{errors.email}</p>}
                                <MobileInput {...rest} {...state} />
                                {rest.touched && error && <p className="error-field">{error}</p>}
                                <div class="password-container flex">
                                    <div class="password-div">
                                        <Field type="password" placeholder="Create Password" name="password" />
                                        {touched.password && <p className="error-field">{errors.password}</p>}
                                        {showPassword ? (
                                            <img
                                                id="password-show"
                                                src={images.showPassword}
                                                alt="password-show"
                                                onClick={() => setShowPassword(false)}
                                            />
                                        ) : (
                                            <img
                                                id="password-hidden"
                                                src={images.hidePassword}
                                                alt="password-hidden"
                                                onClick={() => setShowPassword(true)}
                                            />
                                        )}
                                    </div>
                                    <div class="password-div">
                                        <Field type="password" placeholder="Confirm Password" name="confirmPassword" />
                                        {touched.confirmPassword && (
                                            <p className="error-field">{errors.confirmPassword}</p>
                                        )}
                                        {showConfirmPassword ? (
                                            <img
                                                id="password-show"
                                                src={images.showPassword}
                                                alt="password-show"
                                                onClick={() => setShowConfirmPassword(false)}
                                            />
                                        ) : (
                                            <img
                                                id="password-hidden"
                                                src={images.hidePassword}
                                                alt="password-hidden"
                                                onClick={() => setShowConfirmPassword(true)}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div class="terms">
                                    <label htmlFor="terms">
                                        <Field type="checkbox" id="terms" name="tandc" />
                                        <span>
                                            Agree to our{' '}
                                            <a target="_blank" href={process.env.REACT_APP_TERMS} rel="noreferrer">
                                                Terms and Conditions
                                            </a>
                                        </span>
                                    </label>
                                </div>
                                {touched.tandc && <p className="error-field">{errors.tandc}</p>}
                                <button type="submit" class="btn btn-primary">
                                    Sign Up
                                </button>
                            </Form>
                        )}
                    </Formik>
                    <div class="text-center end already-have">
                        <span>
                            Already have a account? <Link to={SIGNIN_ROUTE}>Sign In</Link>
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SignupPage;
