import React from 'react';

import styles from './Loader.module.css';

function Loader() {
    return (
        <div className={styles.loader}>
            <div
                id="loading-indicator"
                style={{ width: '60px', height: '60px' }}
                role="progressbar"
                className={`${styles['MuiCircularProgress-root']} ${styles['MuiCircularProgress-colorPrimary']} ${styles['MuiCircularProgress-indeterminate']}`}>
                <svg viewBox="22 22 44 44" className={styles['MuiCircularProgress-svg']}>
                    <circle
                        cx="44"
                        cy="44"
                        r="20.2"
                        fill="none"
                        strokeWidth="3.6"
                        className={`${styles['MuiCircularProgress-circle']} ${styles['MuiCircularProgress-circleIndeterminate']}`}></circle>
                </svg>
            </div>
        </div>
    );
}

export default Loader;
