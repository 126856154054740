import axios from 'axios';

import { getAccessTokenFromStore, clearAccessToken, clearRefreshToken } from './global.util';

const baseURL = process.env.REACT_APP_BASE_API_URL;

export const userAxios = axios.create({
    baseURL: baseURL,
});

export const defaultAxios = axios.create({
    baseURL: baseURL,
});

export const authAxios = axios.create({
    baseURL: baseURL,
});

authAxios.interceptors.request.use(request => {
    return request;
});

authAxios.interceptors.response.use(function (response) {
    if (response.status === 401) {
        clearAccessToken();
        clearRefreshToken();
        return (window.location.href = `${baseURL}/signin`);
    }
    return response.data;
});

userAxios.interceptors.request.use(function (request) {
    var token = getAccessTokenFromStore();
    request.headers.Authorization = `Bearer ${token}`;
    return request;
});

userAxios.interceptors.response.use(function (response) {
    return response.data;
});

defaultAxios.interceptors.response.use(function (response) {
    return response.data;
});
