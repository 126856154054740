import DocumentTitle from 'react-document-title'

function setTitle(title) {

    return function withTitle(Component) {

        return function (props) {

            return (
                <DocumentTitle title={title}>
                    <Component {...props} />
                </DocumentTitle>
            );
        }
    }
}



export default setTitle;