export const START_REQUEST = 'START_REQUEST';

export const SIGNIN_WITH_EMAIL_SUCCESS = 'SIGNIN_WITH_EMAIL_SUCCESS';
export const SIGNIN_WITH_EMAIL_FAILURE = 'SIGNIN_WITH_EMAIL_FAILURE';

export const SIGNUP_WITH_EMAIL_SUCCESS = 'SIGNUP_WITH_EMAIL_SUCCESS';
export const SIGNUP_WITH_EMAIL_FAILURE = 'SIGNUP_WITH_EMAIL_FAILURE';

export const SIGNIN_WITH_GOOGLE_SUCCESS = 'SIGNIN_WITH_GOOGLE_SUCCESS';
export const SIGNIN_WITH_GOOGLE_FAILURE = 'SIGNIN_WITH_GOOGLE_FAILURE';

export const SIGNIN_WITH_APPLE_SUCCESS = 'SIGNIN_WITH_APPLE_SUCCESS';
export const SIGNIN_WITH_APPLE_FAILURE = 'SIGNIN_WITH_APPLE_FAILURE';

export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE';

export const UPDATE_PHONE_SUCCESS = 'UPDATE_PHONE_SUCCESS';
export const UPDATE_PHONE_FAILURE = 'UPDATE_PHONE_FAILURE';

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';

export const RESET_PASSWORD_LINK_SUCCESS = 'RESET_PASSWORD_LINK_SUCCESS';
export const RESET_PASSWORD_LINK_FAILURE = 'RESET_PASSWORD_LINK_FAILURE';

export const AUTO_LOGIN_CHECK_SUCCESS = 'AUTO_LOGIN_CHECK_SUCCESS';
export const AUTO_LOGIN_CHECK_FAILURE = 'AUTO_LOGIN_CHECK_FAILURE';

export const LOGOUT = 'LOGOUT';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

export const INIT_CHECK_DONE = 'INIT_CHECK_DONE';
