import * as Yup from 'yup';

const emailValidtor = Yup.string().required('Email is Required').email('Please enter a valid email');
const phoneValidator = Yup.string().required('Phone Number is Required');
// .min(10, 'Please Enter a Valid Mobile Number');

export const phoneProfileSchema = Yup.string();
// .min(10, 'Please Enter a Valid Mobile Number');

export const phoneValidatorProfile = phoneNumber => phoneProfileSchema.validateSync(phoneNumber);

export const validateEmail = email => emailValidtor.validateSync(email);

export const validatePhone = phoneNumber => phoneValidator.validateSync(phoneNumber);

export const profileSchema = Yup.object({
    password: Yup.string().nullable(),
    confirmPassword: Yup.string()
        .nullable()
        .oneOf([Yup.ref('password'), null], 'Password and Confirm Password should match'),
    social: Yup.object({
        facebook: Yup.string().nullable().url('Please enter a valid Url'),
        twitter: Yup.string().nullable().url('Please enter a valid Url'),
        instagram: Yup.string().nullable().url('Please enter a valid Url'),
        linkedin: Yup.string().nullable().url('Please enter a valid Url'),
        youtube: Yup.string().nullable().url('Please enter a valid Url'),
        github: Yup.string().nullable().url('Please enter a valid Url'),
    }),
    email: Yup.string().nullable().email('Please enter a valid Email'),
});

export const signinSchema = Yup.object({
    email: Yup.string().required('Email is Required').email('Please enter a valid email'),
    password: Yup.string().required('Password is Required'),
});

export const signupSchema = Yup.object({
    firstName: Yup.string().required('First Name is Required'),
    lastName: Yup.string().required('Last Name is Required'),
    email: Yup.string().required('Email is Required').email('Please enter a valid email'),
    password: Yup.string().required('Password is Required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
        .required('Confirm Password is Required')
        .oneOf([Yup.ref('password'), null], 'Password and Confirm Password should match'),
    tandc: Yup.boolean().required('Please Agree to Terms and Condition'),
});

export const forgotPasswordSchema = Yup.object({
    password: Yup.string().required('Password is Required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
        .required('Confirm Password is Required')
        .oneOf([Yup.ref('password'), null], 'Password and Confirm Password should match'),
});
