import React, { useReducer } from 'react';
import useToast from '../hooks/useToast';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import userReducer from '../store/reducers/user.reducer';

const INIT_STATE = {
    isLoggedIn: false,
    user: {
        img: {},
        social: {},
    },
    isLoading: false,
    showPhoneModal: false,
    isInitialCheckDone: false,
};

const User = React.createContext(INIT_STATE);

export const UserProvider = function ({ children }) {
    var [state, dispatch] = useReducer(userReducer, INIT_STATE);
    var toasts = useToast();
    const { executeRecaptcha } = useGoogleReCaptcha();

    function myLogger(action) {
        dispatch(action);
    }

    function myDispatch(action) {
        if (typeof action === 'function') {
            return action(myLogger, toasts, executeRecaptcha);
        }
        myLogger(action);
    }

    return (
        <User.Provider
            value={{
                dispatch: myDispatch,
                ...state,
            }}>
            {children}
        </User.Provider>
    );
};

export default User;
