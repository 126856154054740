import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Toast() {
    function showSuccessToast(msg) {
        var options = {
            position: 'top-right',
            autoClose: 1200,
            hideProgressBar: false,
            closeOnClick: true,
        };
        toast.success(msg, options);
    }

    function showFailureToast(msg) {
        var options = {
            position: 'top-right',
            autoClose: 1200,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
        };
        toast.error(msg, options);
    }

    return {
        showSuccessToast,
        showFailureToast,
    };
}

export default Toast;
