import _ from 'lodash';

import * as utils from '../../utils/global.util';
import {
    AUTO_LOGIN_CHECK_FAILURE,
    AUTO_LOGIN_CHECK_SUCCESS,
    SIGNIN_WITH_EMAIL_FAILURE,
    SIGNIN_WITH_EMAIL_SUCCESS,
    SIGNIN_WITH_GOOGLE_FAILURE,
    SIGNIN_WITH_GOOGLE_SUCCESS,
    SIGNIN_WITH_APPLE_FAILURE,
    SIGNIN_WITH_APPLE_SUCCESS,
    SIGNUP_WITH_EMAIL_FAILURE,
    SIGNUP_WITH_EMAIL_SUCCESS,
    START_REQUEST,
    UPDATE_PHONE_FAILURE,
    UPDATE_PHONE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    UPDATE_PROFILE_SUCCESS,
    LOGOUT,
    DELETE_ACCOUNT,
    INIT_CHECK_DONE,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,
} from './user.constant';

export const updatePhoneNumber = (mobileData, access_token, refresh_token) => {
    return async function (dispatch, { showFailureToast, showSuccessToast }, executeRecaptcha) {
        dispatch(startRequest());
        try {
            var token = await executeRecaptcha();
            await utils.updatePhone(mobileData, access_token, token);
            mobileData = _.omit(mobileData, 'error');
            if (window.location.search.includes('fromApp=true')) {
                return window.open(`ineuron://${access_token}/${refresh_token}`, '_self');
                // return window.location.replace(`ineuron://${access_token}/${refresh_token}`);
            }
            utils.setAccessTokenToStore(access_token);
            dispatch(updatePhoneSuccess(mobileData));
            showSuccessToast('Phone Number Updated successfully');
        } catch (err) {
            showFailureToast(err?.response?.data?.error);
            dispatch(updatePhoneFailure());
        }
    };
};

export const signInWithGoogle = ({ tokenId }) => {
    return async function (dispatch, _, executeRecaptcha) {
        try {
            dispatch(startRequest());
            if (tokenId) {
                var token = await executeRecaptcha();
                var response = await utils.socialLogin('google', tokenId, token);
                var userProfile = await utils.getUserDetailsWithToken(response.access_token);
                if (userProfile.data.phoneNumber) {
                    if (window.location.search.includes('fromApp=true')) {
                        return window.open(`ineuron://${response.access_token}/${response.refresh_token}`, '_self');
                        // return window.location.replace(`ineuron://${response.access_token}/${response.refresh_token}`);
                    }
                    utils.setAccessTokenToStore(response.access_token);
                    return dispatch(signInWithGoogleSuccess(userProfile.data));
                }
                return dispatch(
                    signInWithGoogleSuccess({
                        ...userProfile.data,
                        access_token: response.access_token,
                        refresh_token: response.refresh_token,
                    }),
                );
            } else {
                dispatch(
                    signInWithGoogleFailure({
                        message: 'Login Failed',
                    }),
                );
            }
        } catch (err) {
            dispatch(signInWithGoogleFailure());
        }
    };
};

export const signInWithApple = (code, data) => {
    return async function (dispatch, _, executeRecaptcha) {
        try {
            dispatch(startRequest());
            if (code) {
                var token = await executeRecaptcha();
                var response = await utils.socialLogin('apple', code, token, data);
                var userProfile = await utils.getUserDetailsWithToken(response.access_token);
                if (userProfile.data.phoneNumber) {
                    if (window.location.search.includes('fromApp=true')) {
                        return window.open(`ineuron://${response.access_token}/${response.refresh_token}`, '_self');
                        // return window.location.replace(`ineuron://${response.access_token}/${response.refresh_token}`);
                    }
                    utils.setAccessTokenToStore(response.access_token);
                    return dispatch(signInWithAppleSuccess(userProfile.data));
                }
                return dispatch(
                    signInWithAppleSuccess({
                        ...userProfile.data,
                        access_token: response.access_token,
                        refresh_token: response.refresh_token,
                    }),
                );
            } else {
                dispatch(
                    signInWithAppleFailure({
                        message: 'Login Failed',
                    }),
                );
            }
        } catch (err) {
            dispatch(signInWithAppleFailure());
        }
    };
};

export const signInWithEmailAndPassword = ({ email, password }) => {
    return async function (dispatch, { showFailureToast }, executeRecaptcha) {
        try {
            dispatch(startRequest());
            let token = await executeRecaptcha();
            var response = await utils.signInWithEmailAndPassword(email, password, token);
            if (response && window.location.search.includes('fromApp=true')) {
                return window.open(`ineuron://${response.access_token}/${response.refresh_token}`, '_self');
                // return window.location.replace(`ineuron://${response.access_token}/${response.refresh_token}`);
            }
            utils.setAccessTokenToStore(response.access_token);
            var userProfile = await utils.getUserDetails();
            dispatch(signInWithEmailSuccess(userProfile.data));
        } catch (err) {
            if (err?.response?.data?.error) {
                showFailureToast(err?.response?.data?.error);
            } else {
                showFailureToast('Something Went Wrong');
            }
            dispatch(signInWithEmailFailure());
        }
    };
};

export const signUpWithEmail = data => {
    return async function (dispatch, { showFailureToast }, executeRecaptcha) {
        try {
            delete data.confirmPassword;
            dispatch(startRequest());
            let token = await executeRecaptcha();
            let response = await utils.signupWithEmail(data, token);
            if (response && window.location.search.includes('fromApp=true')) {
                return window.open(`ineuron://${response.access_token}/${response.refresh_token}`, '_self');
                // return window.location.replace(`ineuron://${response.access_token}/${response.refresh_token}`);
            }
            utils.setAccessTokenToStore(response.access_token);
            var userProfile = await utils.getUserDetails();
            dispatch(signUpWithEmailSuccess(userProfile.data));
        } catch (err) {
            showFailureToast(err?.response?.data?.error, 'failure');
            dispatch(signUpWithEmailFailure());
        }
    };
};

export const updateProfile = (data, cb) => {
    return async function (dispatch, { showSuccessToast, showFailureToast }, executeRecaptcha) {
        try {
            delete data.confirmPassword;
            dispatch(startRequest());
            var token = await executeRecaptcha();
            await utils.updateUserProfile(data, token);
            dispatch(updateProfileSuccess(data));
            showSuccessToast('Profile updated successfully');
            cb();
        } catch (err) {
            dispatch(updateProfileFailure());
            if (err?.response?.data?.error) {
                return showFailureToast(err.response.data.error);
            }
            showFailureToast('Something went wrong');
        }
    };
};

export const checkForAlreadyLogin = () => {
    return function (dispatch) {
        var accessToken = utils.getAccessTokenFromStore();
        if (accessToken) {
            dispatch(startRequest());
            utils
                .getUserDetails()
                .then(user => {
                    dispatch(autoLoginSuccess(user.data));
                })
                .catch(err => {
                    // if (err?.response?.status === 401) {
                    //     var token = utils.getRefreshTokenFromStore();
                    //     if (token) {
                    //         return utils
                    //             .getNewAccessToken(token)
                    //             .then(token => utils.setAccessTokenToStore(token))
                    //             .then(_ => utils.getUserDetails())
                    //             .then(userDetails => userDetails.data)
                    //             .then(user => dispatch(autoLoginSuccess(user)))
                    //             .catch(err => dispatch(autoLoginFailure()));
                    //     } else {
                    //         dispatch(autoLoginFailure());
                    //     }
                    // } else {
                    //     dispatch(autoLoginFailure());
                    // }
                    dispatch(autoLoginFailure());
                });
        } else {
            dispatch(initialCheckDone());
        }
    };
};

function initialCheckDone() {
    return {
        type: INIT_CHECK_DONE,
    };
}

export const updateProfileImage = (file, userId) => {
    return async function (dispatch, { showSuccessToast, showFailureToast }, executeRecaptcha) {
        try {
            // check the size of the image
            const sizeInKB = file.size / 1024;
            if (sizeInKB > 1024) {
                return showFailureToast('File size should be less than 1MB.');
            }

            dispatch(startRequest());
            var token = await executeRecaptcha();
            var formData = new FormData();
            formData.append('profilePicture', file);
            formData.append('g-recaptcha-response', token);
            await utils.updateProfilePicture(formData);
            showSuccessToast('Profile picture updated successfully');
            dispatch(
                uploadProfileSuccess({
                    link: `${userId}.jpeg`,
                    source: 'bucket',
                }),
            );
        } catch (err) {
            if (err?.response?.data?.error) {
                showFailureToast(err?.response?.data?.error);
            } else {
                showFailureToast('Something Went Wrong');
            }
            dispatch(uploadProfileFailure());
        }
    };
};

export const logout = () => {
    return async function (dispatch) {
        try {
            dispatch(startRequest());
            await utils.logoutAction();
            utils.clearAccessToken();
            utils.clearRefreshToken();
            window.location.href = process.env.REACT_APP_BRAND_DOMAIN;
            dispatch(logoutSuccess());
        } catch (err) {
            dispatch(logoutSuccess());
        }
    };
};

export const actionLogout = domain => {
    return async function (dispatch) {
        try {
            dispatch(startRequest());
            await utils.logoutAction();
            utils.clearAccessToken();
            utils.clearRefreshToken();
            window.location.href = domain;
            dispatch(logoutSuccess());
        } catch (err) {
            dispatch(logoutSuccess());
        }
    };
};

export const deleteAccount = () => {
    return async function (dispatch, _, executeRecaptcha) {
        dispatch(startRequest());
        try {
            var token = await executeRecaptcha();
            await utils.deleteAccountDetails(token);
            dispatch(deleteAccountSuccess());
        } catch (err) {
            dispatch(deleteAccountSuccess());
        }
    };
};

function logoutSuccess() {
    return {
        type: LOGOUT,
    };
}

function deleteAccountSuccess() {
    return {
        type: DELETE_ACCOUNT,
    };
}

function startRequest() {
    return {
        type: START_REQUEST,
    };
}

function uploadProfileSuccess(imageData) {
    return {
        type: UPLOAD_IMAGE_SUCCESS,
        payload: imageData,
    };
}

function uploadProfileFailure() {
    return {
        type: UPLOAD_IMAGE_FAILURE,
    };
}

function signInWithEmailSuccess(user) {
    return {
        type: SIGNIN_WITH_EMAIL_SUCCESS,
        user,
    };
}

function signInWithEmailFailure() {
    return {
        type: SIGNIN_WITH_EMAIL_FAILURE,
    };
}

function signUpWithEmailSuccess(user) {
    return {
        type: SIGNUP_WITH_EMAIL_SUCCESS,
        user,
    };
}

function signUpWithEmailFailure() {
    return {
        type: SIGNUP_WITH_EMAIL_FAILURE,
    };
}

function autoLoginSuccess(user) {
    return {
        type: AUTO_LOGIN_CHECK_SUCCESS,
        user,
    };
}

function autoLoginFailure() {
    return {
        type: AUTO_LOGIN_CHECK_FAILURE,
    };
}

function signInWithGoogleSuccess(user) {
    return {
        type: SIGNIN_WITH_GOOGLE_SUCCESS,
        user,
    };
}

function signInWithGoogleFailure() {
    return {
        type: SIGNIN_WITH_GOOGLE_FAILURE,
    };
}

function signInWithAppleSuccess(user) {
    return {
        type: SIGNIN_WITH_APPLE_SUCCESS,
        user,
    };
}

function signInWithAppleFailure() {
    return {
        type: SIGNIN_WITH_APPLE_FAILURE,
    };
}

function updatePhoneSuccess(mobileData) {
    return {
        type: UPDATE_PHONE_SUCCESS,
        mobile: mobileData,
    };
}

function updatePhoneFailure() {
    return {
        type: UPDATE_PHONE_FAILURE,
    };
}

function updateProfileSuccess(user) {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        user,
    };
}

function updateProfileFailure() {
    return {
        type: UPDATE_PROFILE_FAILURE,
    };
}
